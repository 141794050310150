import { FieldsNamesType } from 'src/types';
import { valSchema } from 'src/utils/valSchema';

import { Checkbox } from '../Checkbox';
import s from './UserAgreement.module.scss';

type UserAgreementCheckboxesProps = {
  agreementName?:
    | 'site_services_consent'
    | 'online_event_consent'
    | 'education_consent';
  checkboxClassName?: string;
  fieldsNames?: FieldsNamesType;
  sendFormFillAnalytics: (fieldName: string) => void;
  withSubscribeForMailing?: boolean;
  isSubscribeForMailingRequired?: boolean;
  onlyMail?: boolean;
  disabled?: boolean;
};

const AGREEMENT_SUBSCRIBE_DOCK =
  'https://cdn.cloud.ru/backend/docs/security/consent_advertising.pdf';

const agreementLinks = {
  site_services_consent:
    'https://cdn.cloud.ru/backend/docs/security/consent_site.pdf',
  online_event_consent:
    'https://cdn.cloud.ru/backend/docs/security/consent_webinar.pdf',
  education_consent:
    'https://cdn.cloud.ru/backend/docs/security/consent_education.pdf',
};

export function UserAgreementCheckboxes({
  agreementName = 'site_services_consent',
  fieldsNames,
  sendFormFillAnalytics,
  withSubscribeForMailing = true,
  isSubscribeForMailingRequired = false,
  checkboxClassName,
  onlyMail = false,
  disabled,
}: UserAgreementCheckboxesProps) {
  const userAgreementDesc = `Я даю <a target="_blank" href="${agreementLinks[agreementName]}">согласие</a> на обработку моих персональных данных в соответствии с условиями <a target="_blank" href="https://cdn.cloud.ru/backend/docs/security/politic.pdf">политики конфиденциальности</a>`;
  const subscribeForMailingDesc = `Я подтверждаю свое <a target="_blank" href="${AGREEMENT_SUBSCRIBE_DOCK}">согласие</a> на получение рекламных и информационных сообщений в соответствии с условиями <a target="_blank" href="https://cdn.cloud.ru/backend/docs/security/politic.pdf">политики конфиденциальности</a> `;

  if (onlyMail) {
    return (
      <div className={s.root}>
        <Checkbox
          label={fieldsNames?.checkboxSubscribe ?? subscribeForMailingDesc}
          value="true"
          name="subscribeForMailing"
          required={isSubscribeForMailingRequired}
          disabled={disabled}
          onChange={(checked) => {
            if (checked) {
              sendFormFillAnalytics('checkbox reklama');
            }
          }}
          className={checkboxClassName}
        />
      </div>
    );
  }

  return (
    <div className={s.root}>
      <Checkbox
        required
        name={agreementName}
        requiredText={valSchema['userAgreement'].required.message}
        label={fieldsNames?.userAgreement ?? userAgreementDesc}
        value="true"
        disabled={disabled}
        onChange={(checked) => {
          if (checked) {
            sendFormFillAnalytics('checkbox agreement');
          }
        }}
        className={checkboxClassName}
      />

      {withSubscribeForMailing && (
        <Checkbox
          label={fieldsNames?.checkboxSubscribe ?? subscribeForMailingDesc}
          value="true"
          name="subscribeForMailing"
          required={isSubscribeForMailingRequired}
          disabled={disabled}
          onChange={(checked) => {
            if (checked) {
              sendFormFillAnalytics('checkbox reklama');
            }
          }}
          className={checkboxClassName}
        />
      )}
    </div>
  );
}
